import { PageProps } from "gatsby";
import React from "react";
import { ThanksForPurchasePage } from "../../../../../components/pages/ThanksForPurchasePage/ThanksForPurchasePage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const imageId = params?.imageId ?? "";
    const jobId = params?.jobId ?? "";
    return <ThanksForPurchasePage imageId={imageId} jobId={jobId} />;
};
export default Page;
