import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { SecondaryButtonLink } from "../../atoms/ButtonLink/SecondaryButtonLink";
import { H2 } from "../../atoms/H2/H2";
import { P } from "../../atoms/P/P";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { FullsizeImage } from "../../molecules/FullsizeImage/FullsizeImage";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { Root } from "./ThanksForPurchasePage.styles";

interface IProps {
    readonly jobId: string;
    readonly imageId: string;
}

export const ThanksForPurchasePage: React.FC<React.PropsWithChildren<IProps>> = ({ imageId, jobId }) => {
    const [t] = useTranslation();
    const description = t("thanksForPurchasePage.description");
    const title = t("thanksForPurchasePage.title");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.PurchasePrintThanks(jobId, imageId)}>
            <WithAuth>
                <Root>
                    <H2>{t("thanksForPurchasePage.heading")}</H2>
                    <FullsizeImage jobId={jobId} imageId={imageId} height={200} width={200} />
                    <P>{t("thanksForPurchasePage.text")}</P>
                    <SecondaryButtonLink to={HeadbotRoute.ManageJob(jobId)}>{t("thanksForPurchasePage.backToGallery")}</SecondaryButtonLink>
                </Root>
            </WithAuth>
        </ScrollablePageTemplate>
    );
};
